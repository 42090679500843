import React, { Component  } from "react"
import { navigate } from 'gatsby'

import SEO from "../components/seo"

class IndexPage extends Component {
  componentDidMount() {
    this.timeout = setTimeout(() => {
      if (window.location.pathname === '/') navigate('/about')
    }, 3000)
  }
  componentWillUnmount() {
    clearTimeout(this.timeout)
  }
  render() {
    return (
      <>
        <SEO bodyClass={'page--landing'} title="Home" keywords={[`gatsby`, `application`, `react`]} />
        <div className='landing' onClick={() => navigate('/about')}>
          <h2>&nbsp;</h2>
          <p>
            Welcome to Peak Marketing & Communication Services <br />
            ‘Peak’ as we are most commonly known.
          </p>
        </div>
      </>
    )
  }
}

export default IndexPage
